import {
  ACTIONS,
  activity,
  AnalyticsEventPropTypes,
  AnalyticsEventWithControlName,
  eventDetailVersion,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { screenName, screenPath } from 'src/utils/constants';
import { getCardsInstantInkRequestMoreInk, URLS } from 'src/utils/urls';
import { SubscriptionCounts } from '.';

export const HomeScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const ChangePasswordHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePasswordLink',
  controlDetail: URLS.Cards.LoginAndSecurity.ChangePassword,
  version: eventDetailVersion
};

export const ChangePasswordOnPortalHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangePasswordOnPortalLink',
    controlDetail: URLS.Cards.LoginAndSecurity.ChangePassword + '/password',
    version: eventDetailVersion
  };

export const UpdatePersonalDetailsHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'UpdatePersonalDetailsLink',
    controlDetail: URLS.Cards.LoginAndSecurity.UpdatePersonalDetails,
    version: eventDetailVersion
  };

export const ViewOrderHistoryHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ViewOrderHistoryLink',
  controlDetail: URLS.Cards.YourPurchases.ViewOrderHistory,
  version: eventDetailVersion
};

export const StartReturnHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'StartReturnLink',
  controlDetail: URLS.Cards.YourPurchases.StartReturn,
  version: eventDetailVersion
};

export const PrinterShowsAccountIssueHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'PrinterShowsAccountIssueLink',
    controlDetail: URLS.Cards.OtherLinks.PrinterShowsAccountIssue,
    version: eventDetailVersion
  };

export const AddPrinterHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'AddPrinterLink',
  controlDetail: URLS.Cards.OtherLinks.AddPrinter,
  version: eventDetailVersion
};

export const GetProductSupportHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'GetProductSupportLink',
    controlDetail: URLS.Cards.OtherLinks.GetProductSupport,
    version: eventDetailVersion
  };

export const RegisterDeviceHyperlinkClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'RegisterDeviceLink',
  controlDetail: URLS.Cards.OtherLinks.RegisterDevice,
  version: eventDetailVersion
};

export const UpdateInstantInkPaymentMethodHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'UpdateInstantInkPaymentMethodLink',
    controlDetail: URLS.Cards.InstantInk.UpdatePaymentMethod,
    version: eventDetailVersion
  };

export const ChangeInstantInkPlanHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangeInstantInkPlanLink',
    controlDetail: URLS.Cards.InstantInk.ChangePauseOrCancelPlan,
    version: eventDetailVersion
  };

export const ViewInstantInkPrintUsageHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ViewInstantInkPrintUsageLink',
    controlDetail: URLS.Cards.InstantInk.ViewPrintUsage,
    version: eventDetailVersion
  };

export const UpdateHpAllInPaymentMethodHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'UpdateHpAllInPaymentMethodLink',
    controlDetail: URLS.Cards.HpAllIn.UpdatePaymentMethod,
    version: eventDetailVersion
  };

export const ChangeHpAllInPlanHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ChangeHpAllInPlanLink',
    controlDetail: URLS.Cards.HpAllIn.ChangeOrCancelPlan,
    version: eventDetailVersion
  };

export const ViewHpAllInPrintUsageHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ViewHpAllInPrintUsageLink',
    controlDetail: URLS.Cards.HpAllIn.ViewPrintUsage,
    version: eventDetailVersion
  };

export const ContactHpAllInProLiveSupportHyperlinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'ContactHpAllInProLiveSupportLink',
    controlDetail: URLS.Cards.HpAllIn.ContactProLiveSupport,
    version: eventDetailVersion
  };

export const RequestMoreInkHyperlinkClicked = (
  language: string = 'en',
  country: string = 'us'
): AnalyticsEventWithControlName => ({
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'RequestMoreInkLink',
  controlDetail: getCardsInstantInkRequestMoreInk(language, country),
  version: eventDetailVersion
});

export const ShowInstantInkCardModuleDisplayed = (
  subscriptionCounts: SubscriptionCounts = {
    totalActiveInstantInkCt: 0,
    totalInactiveInstantInkCt: 0,
    totalActiveHpAllInCt: 0,
    totalInactiveHpAllInCt: 0,
    totalActiveSubs: 0,
    totalInactiveSubs: 0
  }
): AnalyticsEventWithControlName => {
  const {
    totalActiveInstantInkCt,
    totalInactiveInstantInkCt,
    totalActiveHpAllInCt,
    totalInactiveHpAllInCt,
    totalActiveSubs,
    totalInactiveSubs
  } = subscriptionCounts;
  return {
    actionAuxParams: `totalActiveInstantInkCt=${totalActiveInstantInkCt}&totalInactiveInstantInkCt=${totalInactiveInstantInkCt}&totalActiveHpAllInCt=${totalActiveHpAllInCt}&totalInactiveHpAllInCt=${totalInactiveHpAllInCt}&totalActiveSubs=${totalActiveSubs}&totalInactiveSubs=${totalInactiveSubs}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName,
    controlName: 'InstantInkCard',
    version: eventDetailVersion
  };
};

export const ShowHpAllInCardModuleDisplayed = (
  subscriptionCounts: SubscriptionCounts = {
    totalActiveInstantInkCt: 0,
    totalInactiveInstantInkCt: 0,
    totalActiveHpAllInCt: 0,
    totalInactiveHpAllInCt: 0,
    totalActiveSubs: 0,
    totalInactiveSubs: 0
  }
): AnalyticsEventWithControlName => {
  const {
    totalActiveInstantInkCt,
    totalInactiveInstantInkCt,
    totalActiveHpAllInCt,
    totalInactiveHpAllInCt,
    totalActiveSubs,
    totalInactiveSubs
  } = subscriptionCounts;
  return {
    actionAuxParams: `totalActiveInstantInkCt=${totalActiveInstantInkCt}&totalInactiveInstantInkCt=${totalInactiveInstantInkCt}&totalActiveHpAllInCt=${totalActiveHpAllInCt}&totalInactiveHpAllInCt=${totalInactiveHpAllInCt}&totalActiveSubs=${totalActiveSubs}&totalInactiveSubs=${totalInactiveSubs}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName,
    controlName: 'HpAllInCard',
    version: eventDetailVersion
  };
};

interface HandleSubscriptionCardsAnalyticsEventsProps {
  instantInkSubDifferentThanInactiveExists: boolean;
  hpAllInSubDifferentThanInactiveExists: boolean;
  subscriptionCounts: SubscriptionCounts;
}

export const handleSubscriptionCardsAnalyticsEvents = ({
  instantInkSubDifferentThanInactiveExists,
  hpAllInSubDifferentThanInactiveExists,
  subscriptionCounts
}: HandleSubscriptionCardsAnalyticsEventsProps) => {
  if (
    instantInkSubDifferentThanInactiveExists &&
    hpAllInSubDifferentThanInactiveExists
  ) {
    publishEvent([
      ShowInstantInkCardModuleDisplayed(subscriptionCounts),
      ShowHpAllInCardModuleDisplayed(subscriptionCounts)
    ]);
    return;
  }

  if (instantInkSubDifferentThanInactiveExists) {
    publishEvent(ShowInstantInkCardModuleDisplayed(subscriptionCounts));
    return;
  }
  if (hpAllInSubDifferentThanInactiveExists) {
    publishEvent(ShowHpAllInCardModuleDisplayed(subscriptionCounts));
    return;
  }
};
