import {
  getSubscriptionCommercialState,
  SubscriptionType,
  CommercialSubscriptionStates
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { Subscription, SubscriptionCounts } from '..';

export const getSubscriptionCounts = (
  subscriptions: Subscription[] | null | undefined
): SubscriptionCounts => {
  if (!subscriptions || !Array.isArray(subscriptions)) {
    return {
      totalActiveInstantInkCt: 0,
      totalInactiveInstantInkCt: 0,
      totalActiveHpAllInCt: 0,
      totalInactiveHpAllInCt: 0,
      totalActiveSubs: 0,
      totalInactiveSubs: 0
    };
  }

  return subscriptions.reduce(
    (acc, sub) => {
      const { state, type } = sub;
      const commercialState = getSubscriptionCommercialState(state, type);
      if (type === SubscriptionType.INSTANT_INK) {
        if (commercialState === CommercialSubscriptionStates.ACTIVE) {
          acc.totalActiveInstantInkCt += 1;
          acc.totalActiveSubs += 1;
        } else if (commercialState === CommercialSubscriptionStates.INACTIVE) {
          acc.totalInactiveInstantInkCt += 1;
          acc.totalInactiveSubs += 1;
        }
      }

      if (type === SubscriptionType.HP_ALL_IN) {
        if (commercialState === CommercialSubscriptionStates.ACTIVE) {
          acc.totalActiveHpAllInCt += 1;
          acc.totalActiveSubs += 1;
        } else if (commercialState === CommercialSubscriptionStates.INACTIVE) {
          acc.totalInactiveHpAllInCt += 1;
          acc.totalInactiveSubs += 1;
        }
      }

      return acc;
    },
    {
      totalActiveInstantInkCt: 0,
      totalInactiveInstantInkCt: 0,
      totalActiveHpAllInCt: 0,
      totalInactiveHpAllInCt: 0,
      totalActiveSubs: 0,
      totalInactiveSubs: 0
    }
  );
};
