import {
  AnalyticsEventPropTypes,
  LookingForSomethingElse,
  SectionHeader,
  WelcomeNonUsUsers,
  publishEvent,
  useReadOnlyDataStreamer
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FeatureFlagProps } from 'src/App';
import FrameCards from 'src/components/FrameCards';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_SUBSCRIPTIONS from 'src/graphql/getSubscriptions';
import { screenName, screenPath } from 'src/utils/constants';
import getFieldsToFetch from '../../utils/getFieldsToFetch';
import { SubscriptionsError, UserNameError } from './analytics';
import { Header } from './styles';

export interface PersonalDetailsType {
  firstName?: string;
  country?: string;
}

const Home = (featureFlagValue: FeatureFlagProps) => {
  const [hpIdUser, setHpIdUser] = useState<PersonalDetailsType>({
    firstName: '',
    country: ''
  });

  const { error: errorHpIdUser, loading: loadingHpIdUser } =
    useReadOnlyDataStreamer(setHpIdUser, getFieldsToFetch);

  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { useQuery } = northboundAPIs.v1.graphql.reactTools;

  const {
    error: subscriptionsError,
    loading: isSubscriptionsLoading,
    data: subscriptionsData
  } = useQuery(GET_SUBSCRIPTIONS);

  const { subscriptions } = subscriptionsData?.account || {};

  const language = navigator.language;

  const { isLoading, welcomeNonUsUsers, originalFeedbackExperience } =
    featureFlagValue;

  const showWelcomeNonUsUsers = useMemo(() => {
    if (loadingHpIdUser || isLoading || !welcomeNonUsUsers) {
      return false;
    }

    const isNonUsUser = hpIdUser?.country && hpIdUser.country !== 'US';
    const isNonUsLanguage = language !== 'en-US';

    return isNonUsUser || (errorHpIdUser && isNonUsLanguage);
  }, [
    errorHpIdUser,
    hpIdUser.country,
    isLoading,
    language,
    loadingHpIdUser,
    welcomeNonUsUsers
  ]);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isLoading;

  const welcomeText = useCallback(() => {
    if (loadingHpIdUser || !hpIdUser.firstName) {
      return translate('home.welcome', 'Welcome');
    }

    return translate('home.welcomeUser', {
      defaultValue: 'Welcome, {{user}}',
      replace: { user: hpIdUser.firstName }
    });
  }, [hpIdUser.firstName, loadingHpIdUser, translate]);

  useEffect(() => {
    const analytics: AnalyticsEventPropTypes[] = [];
    if (subscriptionsError) {
      analytics.push(SubscriptionsError(subscriptionsError.message));
    }

    if (errorHpIdUser) {
      analytics.push(UserNameError(`Error fetching field firstName from HPID`));
    }
    analytics.length > 0 &&
      publishEvent(analytics as unknown as AnalyticsEventPropTypes);
  }, [errorHpIdUser, hpIdUser.firstName, subscriptionsError, translate]);

  return (
    <>
      {showWelcomeNonUsUsers && (
        <WelcomeNonUsUsers
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          stack={northboundAPIs.v1.app.getAuthStack()}
          translate={translate}
        />
      )}
      <Header data-testid="header">
        <SectionHeader
          title={welcomeText()}
          subtitle={translate(
            'home.mainTitle',
            'Quick links to your info, subscriptions and purchases with HP'
          )}
          dataTestidTitle={
            !loadingHpIdUser && hpIdUser.firstName ? 'username' : null
          }
        />
      </Header>
      <FrameCards
        subscriptions={subscriptions}
        isSubscriptionsLoading={isSubscriptionsLoading}
        featureFlags={featureFlagValue}
      />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};
export default Home;
