import {
  ApiSubscriptionStates,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useMemo } from 'react';
import { FeatureFlagProps } from 'src/App';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { handleSubscriptionCardsAnalyticsEvents } from './analytics';
import {
  getHpAllInCard,
  getInstantInkCard,
  getLoginAndSecurityCard,
  getOtherLinksCard,
  getYourPurchasesCard
} from './cardDefinitions';
import FrameCard, { IFrameCardProps } from './FrameCardTemplate';
import { addCardIf } from './helpers/addCardIf';
import { checkIfSubDifferentThanInactiveExists } from './helpers/checkIfSubDifferentThanInactiveExists';
import { getSubscriptionCounts } from './helpers/getSubscriptionCounts';
import { Frame } from './styles';

export interface SubscriptionCounts {
  totalActiveInstantInkCt: number;
  totalInactiveInstantInkCt: number;
  totalActiveHpAllInCt: number;
  totalInactiveHpAllInCt: number;
  totalActiveSubs: number;
  totalInactiveSubs: number;
}

export interface Subscription {
  state: ApiSubscriptionStates;
  type: SubscriptionType;
}

export interface FrameCardsProps {
  subscriptions: Subscription[] | null | undefined;
  isSubscriptionsLoading: boolean;
  featureFlags: FeatureFlagProps;
}

const FrameCards = ({
  subscriptions,
  isSubscriptionsLoading,
  featureFlags
}: FrameCardsProps) => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const language = northboundAPIs?.v1.localization.language;
  const country = northboundAPIs?.v1.localization.country;

  const {
    isLoading,
    securityHPIDPassword: flagChangePassword,
    profileListPersonalDetails: flagUpdatePersonalDetails
  } = featureFlags;

  const cards: IFrameCardProps[] = useMemo(() => {
    const subscriptionCounts = getSubscriptionCounts(subscriptions);
    const {
      instantInkSubDifferentThanInactiveExists,
      hpAllInSubDifferentThanInactiveExists
    } = checkIfSubDifferentThanInactiveExists(subscriptions);

    const initialCards: IFrameCardProps[] = [
      getLoginAndSecurityCard(
        translate,
        flagChangePassword,
        flagUpdatePersonalDetails,
        isLoading
      ),
      getYourPurchasesCard(translate),
      getOtherLinksCard(translate)
    ];

    if (isSubscriptionsLoading) {
      initialCards.push({
        loading: true
      });
    } else {
      addCardIf(
        instantInkSubDifferentThanInactiveExists,
        getInstantInkCard(translate, language, country),
        initialCards
      );
      addCardIf(
        hpAllInSubDifferentThanInactiveExists,
        getHpAllInCard(translate),
        initialCards
      );
      handleSubscriptionCardsAnalyticsEvents({
        instantInkSubDifferentThanInactiveExists,
        hpAllInSubDifferentThanInactiveExists,
        subscriptionCounts
      });
    }

    return initialCards;
  }, [
    country,
    flagChangePassword,
    flagUpdatePersonalDetails,
    isLoading,
    isSubscriptionsLoading,
    language,
    subscriptions,
    translate
  ]);

  return (
    <Frame data-testid="frame-cards">
      {cards.map((card) => (
        <FrameCard
          key={card.title}
          card={card}
        />
      ))}
    </Frame>
  );
};

export default FrameCards;
