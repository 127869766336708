import { IFrameCardProps } from '../FrameCardTemplate';

export const addCardIf = (
  condition: boolean,
  card: IFrameCardProps,
  cards: IFrameCardProps[]
) => {
  if (condition) {
    cards.push(card);
  }
};
