import React from 'react';
import { getCardsInstantInkRequestMoreInk, URLS } from 'src/utils/urls';
import {
  ChangePasswordHyperlinkClicked,
  ChangePasswordOnPortalHyperlinkClicked,
  UpdatePersonalDetailsHyperlinkClicked,
  ViewOrderHistoryHyperlinkClicked,
  StartReturnHyperlinkClicked,
  PrinterShowsAccountIssueHyperlinkClicked,
  AddPrinterHyperlinkClicked,
  GetProductSupportHyperlinkClicked,
  RegisterDeviceHyperlinkClicked,
  UpdateInstantInkPaymentMethodHyperlinkClicked,
  ChangeInstantInkPlanHyperlinkClicked,
  ViewInstantInkPrintUsageHyperlinkClicked,
  RequestMoreInkHyperlinkClicked,
  UpdateHpAllInPaymentMethodHyperlinkClicked,
  ChangeHpAllInPlanHyperlinkClicked,
  ViewHpAllInPrintUsageHyperlinkClicked,
  ContactHpAllInProLiveSupportHyperlinkClicked
} from './analytics';
import { IFrameCardProps } from './FrameCardTemplate';
import {
  IconLockAccount,
  IconPrinterInkDrop,
  IconRearrange,
  IconShoppingCart,
  IconTruck
} from '@veneer/core';
import { TranslateType } from 'src/contexts/dependencyManager/types';

export const getLoginAndSecurityCard = (
  translate: TranslateType,
  flagChangePassword: boolean,
  flagUpdatePersonalDetails: boolean,
  isLoading: boolean
): IFrameCardProps => ({
  title: translate('home.cards.loginAndSecurity.title', 'Login & security'),
  urls: [
    {
      link:
        flagChangePassword && !isLoading
          ? URLS.Cards.LoginAndSecurity.ChangePasswordOnPortal
          : URLS.Cards.LoginAndSecurity.ChangePassword,
      text: translate(
        'home.cards.loginAndSecurity.changePassword',
        'Change password'
      ),
      analyticsEvent:
        flagChangePassword && !isLoading
          ? ChangePasswordOnPortalHyperlinkClicked
          : ChangePasswordHyperlinkClicked,
      isInternalLink: flagChangePassword && !isLoading
    },
    {
      link:
        flagUpdatePersonalDetails && !isLoading
          ? URLS.Cards.LoginAndSecurity.UpdatePersonalDetailsOnPortal
          : URLS.Cards.LoginAndSecurity.UpdatePersonalDetails,
      text: translate(
        'home.cards.loginAndSecurity.updatePersonalDetails',
        'Update personal details'
      ),
      analyticsEvent: UpdatePersonalDetailsHyperlinkClicked,
      isInternalLink: flagUpdatePersonalDetails && !isLoading
    }
  ],
  icon: <IconLockAccount size={30} />
});

export const getYourPurchasesCard = (
  translate: TranslateType
): IFrameCardProps => ({
  title: translate('home.cards.yourPurchases.title', 'Your purchases'),
  urls: [
    {
      link: URLS.Cards.YourPurchases.ViewOrderHistory,
      text: translate(
        'home.cards.yourPurchases.viewOrderHistory',
        'View order history'
      ),
      analyticsEvent: ViewOrderHistoryHyperlinkClicked
    },
    {
      link: URLS.Cards.YourPurchases.StartReturn,
      text: translate('home.cards.yourPurchases.startReturn', 'Start a return'),
      analyticsEvent: StartReturnHyperlinkClicked
    }
  ],
  icon: <IconShoppingCart size={30} />
});

export const getOtherLinksCard = (
  translate: TranslateType
): IFrameCardProps => ({
  title: translate('home.cards.otherLinks.title', 'Other links'),
  urls: [
    {
      link: URLS.Cards.OtherLinks.PrinterShowsAccountIssue,
      text: translate(
        'home.cards.otherLinks.printerShowsAccountIssue',
        'Printer shows account issue'
      ),
      analyticsEvent: PrinterShowsAccountIssueHyperlinkClicked
    },
    {
      link: URLS.Cards.OtherLinks.AddPrinter,
      text: translate(
        'home.cards.otherLinks.addPrinterToAccount',
        'Add a printer to your account'
      ),
      analyticsEvent: AddPrinterHyperlinkClicked
    },
    {
      link: URLS.Cards.OtherLinks.GetProductSupport,
      text: translate(
        'home.cards.otherLinks.getProductSupport',
        'Get product support'
      ),
      analyticsEvent: GetProductSupportHyperlinkClicked
    },
    {
      link: URLS.Cards.OtherLinks.RegisterDevice,
      text: translate(
        'home.cards.otherLinks.registerDevice',
        'Register a device'
      ),
      analyticsEvent: RegisterDeviceHyperlinkClicked
    }
  ],
  icon: <IconRearrange size={30} />
});

export const getInstantInkCard = (
  translate: TranslateType,
  language: string,
  country: string
): IFrameCardProps => ({
  title: translate('common.instantInk', 'HP Instant Ink'),
  urls: [
    {
      link: URLS.Cards.InstantInk.UpdatePaymentMethod,
      text: translate(
        'home.cards.updatePaymentMethod',
        'Update payment method'
      ),
      analyticsEvent: UpdateInstantInkPaymentMethodHyperlinkClicked
    },
    {
      link: URLS.Cards.InstantInk.ChangePauseOrCancelPlan,
      text: translate(
        'home.cards.hpInstantInk.changePauseOrCancelPlan',
        'Change, pause, or cancel plan'
      ),
      analyticsEvent: ChangeInstantInkPlanHyperlinkClicked
    },
    {
      link: URLS.Cards.InstantInk.ViewPrintUsage,
      text: translate('home.cards.viewPrintUsage', 'View print usage'),
      analyticsEvent: ViewInstantInkPrintUsageHyperlinkClicked
    },
    {
      link: getCardsInstantInkRequestMoreInk(language, country),
      text: translate(
        'home.cards.hpInstantInk.requestMoreInk',
        'Request more ink'
      ),
      analyticsEvent: RequestMoreInkHyperlinkClicked(language, country)
    }
  ],
  icon: <IconTruck size={30} />
});

export const getHpAllInCard = (translate: TranslateType): IFrameCardProps => ({
  title: translate('common.allIn', 'HP All-In Plan'),
  urls: [
    {
      link: URLS.Cards.HpAllIn.UpdatePaymentMethod,
      text: translate(
        'home.cards.updatePaymentMethod',
        'Update payment method'
      ),
      analyticsEvent: UpdateHpAllInPaymentMethodHyperlinkClicked
    },
    {
      link: URLS.Cards.HpAllIn.ChangeOrCancelPlan,
      text: translate(
        'home.cards.hpAllIn.changeOrCancelPlan',
        'Change or cancel plan'
      ),
      analyticsEvent: ChangeHpAllInPlanHyperlinkClicked
    },
    {
      link: URLS.Cards.HpAllIn.ViewPrintUsage,
      text: translate('home.cards.viewPrinterUsage', 'View print usage'),
      analyticsEvent: ViewHpAllInPrintUsageHyperlinkClicked
    },
    {
      link: URLS.Cards.HpAllIn.ContactProLiveSupport,
      text: translate(
        'home.cards.hpAllIn.contactProLiveSupport',
        'Contact 24/7 Pro live support'
      ),
      analyticsEvent: ContactHpAllInProLiveSupportHyperlinkClicked
    }
  ],
  icon: <IconPrinterInkDrop size={30} />
});
