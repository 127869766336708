import {
  ACTIONS,
  activity,
  AnalyticsEventWithControlName,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { screenName, screenPath } from 'src/utils/constants';

export const UserNameError = (
  errorInfo: string
): AnalyticsEventWithControlName => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenName,
    screenPath: screenPath,
    controlName: 'UserNameError',
    version: eventDetailVersion
  };
};

export const SubscriptionsError = (
  errorInfo: string
): AnalyticsEventWithControlName => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenName,
    screenPath: screenPath,
    controlName: 'SubscriptionsError',
    version: eventDetailVersion
  };
};
