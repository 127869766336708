import {
  SubscriptionType,
  getSubscriptionCommercialState,
  CommercialSubscriptionStates
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { Subscription } from '..';

export const checkIfSubDifferentThanInactiveExists = (
  subscriptions: Subscription[]
) => {
  if (!subscriptions || !Array.isArray(subscriptions)) {
    return {
      instantInkSubDifferentThanInactiveExists: false,
      hpAllInSubDifferentThanInactiveExists: false
    };
  }

  return subscriptions.reduce(
    (acc, sub) => {
      const commercialState = getSubscriptionCommercialState(
        sub.state,
        sub.type
      );

      if (
        sub.type === SubscriptionType.INSTANT_INK &&
        commercialState !== CommercialSubscriptionStates.INACTIVE
      ) {
        acc.instantInkSubDifferentThanInactiveExists = true;
      }

      if (
        sub.type === SubscriptionType.HP_ALL_IN &&
        commercialState !== CommercialSubscriptionStates.INACTIVE
      ) {
        acc.hpAllInSubDifferentThanInactiveExists = true;
      }

      return acc;
    },
    {
      instantInkSubDifferentThanInactiveExists: false,
      hpAllInSubDifferentThanInactiveExists: false
    }
  );
};
