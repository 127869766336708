import {
  ContainerMFE,
  publishEvent,
  useGetFeatureFlags,
  FeatureFlagKeyValues
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect, useMemo } from 'react';
import { HomeScreenDisplayed } from 'src/components/FrameCards/analytics';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Home from 'src/pages/Home';
import * as T from './types';

export interface FeatureFlagProps {
  welcomeNonUsUsers: boolean;
  originalFeedbackExperience: boolean;
  securityHPIDPassword: boolean;
  profileListPersonalDetails: boolean;
  isLoading: boolean;
}

const MainComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );

  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const featureFlagValue = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [
      FeatureFlagKeyValues.welcomeNonUsUsers,
      FeatureFlagKeyValues.originalFeedbackExperience,
      FeatureFlagKeyValues.securityHPIDPassword,
      FeatureFlagKeyValues.profileListPersonalDetails
    ]
  });

  return (
    <GraphQLProvider client={client}>
      <ContainerMFE data-testid="home-page">
        <Home {...featureFlagValue} />
      </ContainerMFE>
    </GraphQLProvider>
  );
};

const App: React.FC<T.AppPropsType> = () => {
  useEffect(() => {
    publishEvent(HomeScreenDisplayed);
  }, []);

  return (
    <DependencyManagerProvider>
      <MainComponent />
    </DependencyManagerProvider>
  );
};

export default App;
